.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.new-App-header {
  min-height: 624px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-size: cover; /* Make the background image cover the entire header */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  width: 100%; /* Ensure the header takes up the full width */
  position: relative; /* Set position to relative for text-overlay positioning */
}

.text-overlay {
  position: absolute; /* Position text absolutely within the header */
  color: white; /* Set text color to white for better contrast */
  font-size: 2em; /* Increase font size for better visibility */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow to text for better readability */
}


.App-header {
  min-height: 624px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile size */
h1 {
  font-size: 40px;
  font-weight: 400;
  line-height: 54px;
}

h2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 40.8px;
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 33.12px;
}

h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24.96px;
}

h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.96px;
}

p {
  font-size: 16px;
  font-weight: 400;
}

/* Desktop size */
@media screen and (min-width: 768px) {
  h1 {
    font-size: 96px;
    line-height: 86.4px;
  }

  h2 {
    font-size: 72px;
    line-height: 64.8px;
  }

  h3 {
    font-size: 32px;
    line-height: 48px;
  }

  h4 {
    font-size: 32px;
    line-height: 44.16px;
  }

  h5 {
    font-size: 24px;
    line-height: 33.12px;
  }

  h6 {
    font-size: 24px;
    line-height: 33.12px;
  }

  /* Custom styles */
  .line-height-5 {
    line-height: 29.44px;
  }

  .line-height-4 {
    line-height: 24px;
  }
}




.darkSectionbg{
  background-color: #282c34;
  color: white;
}

.imagedSectionBG{
  background-image: url("./assets/section4Homebg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

/* tablet size */
/* @media screen and (min-width:600px) {
  h1 {
    font-size: calc(10px + 6vmin);
  }
  h2 {
    font-size: 5vw;
  }
  h3 {
    font-size: 4.25vw;
  }
  h4 {
    font-size: 3.5vw;
  }
  h5 {
    font-size: 2.9vw;
  }
  h6 {
    font-size: 2vw;
  }
  p{
    font-size: calc(10px + 2vmin);
  }
} */